<template>
  <div
      class="flex flex-col items-center justify-between rounded-md mx-2 hover:bg-white h-full cursor-pointer pb-2 border border-transparent hover:border-a-neutral-dim hover:shadow-md"
  >
    <!-- Card Content -->
    <div class="flex flex-col w-full h-full ">
      <!-- Image -->
      <NuxtImg
          :src="fundraisingCampaign.image"
          alt=""
          class="object-cover h-40 rounded-t-md"
      />
      <!-- Image Ends -->
      <div class="flex flex-1 flex-col w-full px-2">
      <!-- Title -->
      <p class="font-semibold my-1">
        {{ excerptedTitle }}
      </p>
      <!-- Title Ends -->
      <!-- Progress Bar -->
      <div class="flex flex-col text-xs text-a-neutral-light font-semibold">
        <div
            class="flex flex-row h-2 bg-a-neutral-dim rounded-full relative my-2"
        >
          <div
              class="flex flex-row bg-a-primary rounded-full text-white transition duration-500 ease-in-out animate-pulse"
              :style="
              'width:' + fundraisingCampaign.raised_funds_percentage + '%'
            "
          ></div>
        </div>
        <span class="mx-auto">
          ${{ fundraisingCampaign.raised_amount_in_dollars }} Of ${{
            fundraisingCampaign.goal_amount_in_dollars
          }}
          Raised ({{ fundraisingCampaign.raised_funds_percentage }}%)
        </span>
      </div>
      <!-- Progress Bar Ends -->
      <!-- Fund Needed By -->
      <div class="flex flex-row items-center justify-start py-2">
        <Icon
            name="mdi:calendar"
            class="text-xl text-a-neutral-light"
        />
        <span class="text-xs mx-1">
          Needed By : {{ fundraisingCampaign.needed_by }}
        </span>
      </div>
      <!-- Fund Needed By Ends -->
    </div>
    <!-- Card Content Ends-->
    <!-- Card Meta -->
    <div class="flex flex-row items-center justify-between w-full px-1 my-2">
      <div class="flex flex-row w-1/2">
      <p
          class="text-center bg-a-secondary-lightest text-a-secondary border-a-secondary border-2 rounded text-xs px-1 mx-0.5 my-1 py-0.5"
      >
        {{ fundraisingCampaign.category }}
      </p>
      </div>
      <div
          class="flex flex-row items-center justify-start font-semibold text-xs text-a-neutral-light"
      >
          <p>{{ fundraisingCampaign.created_at_formatted }}</p>
        <BaseTooltip
            trigger-icon="material-symbols:info-rounded"
            :tooltipText="fundraisingCampaign.created_at"
        />
      </div>
    </div>
    <!-- Card Meta Ends -->
    </div>
  </div>
</template>

<script setup lang="ts">
import type {HomeFundraisingCampaign} from "~/types/main";
  const props = withDefaults(defineProps<{
    fundraisingCampaign: HomeFundraisingCampaign
  }>(),{});

const excerptedTitle = computed(()=>{
  if(props.fundraisingCampaign.title.length <= 70){
    return props.fundraisingCampaign.title
  }
  return (props.fundraisingCampaign.title as string).slice(0,70)+'...';
})
</script>

<style></style>
